<template>
	<Toast />
	<Utils ref='Utils' @hideLoginContainer='hideLoginContainer' @changeUser='changeUser' />
	<uploadFile v-model:visible="show_add_paper_dialog" :upload_url_copy='upload_url'></uploadFile>
	<div class="p-grid panel-demo">
		<div class="p-col-12 p-md-6">
			<Card>
				<template v-slot:title>
					<h5>立即签到</h5>
					<Button label="立即签到" style="margin-top: 0.625rem;" icon="pi pi-fw pi-check-square" class="p-button-success p-mr-2"
						@click="todayCheckIn" title='立即签到' />
					<Button type="button" style="margin-top: 0.625rem;" icon='pi pi-thumbs-up'
						title='我相信世界是因为大家相互扶持,才会变得越来越好' label="赞赏支持" @click="toggle" class="p-button-warning p-mr-2" />
					<OverlayPanel ref="op" appendTo="body" :showCloseIcon="true">
						<img src="assets/layout/images/charge.jpg" style='width: 350px;' alt="charge" />
					</OverlayPanel>
					<a target="_blank" v-if='show_download_button' :href="pdf_file_url"><Button label="下载PDF"
							icon="pi pi-download" class="p-button-info p-mr-2" title='下载转换成功的文献' /></a>
				</template>
				<template v-slot:subtitle>
					<!-- 					<p>截至{{file_time}}，已成功免费转换 <span style="color:#0081ff;font-weight: 700;"> {{file_number}}份
						</span>文件。秋招无果更加让我意识到除了帮助值得帮助的人，人生并没有太多意义，还好有这个项目让我觉得自己还有点价值。希望大家<span
							style="color:#0081ff;font-weight: 700;">多多赞赏支持</span>，让我们共同维护项目长久，帮助更多人!</p> -->
					<p>在校研究生自费运维的项目，希望大家<span style="color:#0081ff;font-weight: 700;">赞赏支持</span>，共同维护项目长久。</p>
					<a href="https://mp.weixin.qq.com/s/uutCgLO8aVEOskqBPfhbcA" target="_blank">
						<Button title='做专业的学术研究，从拥有自己专业的学术词典开始！' icon="pi pi-external-link"
							style="font-weight: 800;margin-top: 0.625rem;" label="所有专业学术词典 Word 版开放获取!"
							class="p-button-info mb-3 p-mr-2" />
					</a>
				</template>

				<template v-slot:content>
					<transition-group name="p-messages" tag="div">
						<Message :severity="'info'"><span
								style="font-weight: 800;">签到后可获得临时会员，免费使用“桌面端Caj2PDF-OCR工具”的功能，每次签到会员有效期为24小时，祝大家使用愉快</span>
						</Message>
				<!-- 		<Message :severity="'warn'"><span
								style="font-weight: 800;">提示：若转换至100%后卡顿，使用电脑从微信公众号进入萤火虫后再转换可解决</span>
						</Message> -->
					</transition-group>
					<transition-group name="p-messages" tag="div" v-for="(item, j) of procedure_list" :key="j">
						<Message :severity="item.severity"><span
								style="font-weight: 800;">{{item.name}}</span>{{item.content}}</Message>
					</transition-group>
				</template>
			</Card>
			<Card style='margin-top: 20px;'>
				<template v-slot:title>
					<h5>桌面端Caj2PDF-OCR工具</h5>
					<a href="https://www.aliyundrive.com/s/rx9yGeaH5KX" target="_blank">
						<Button title='通过阿里云盘下载(提取码: 1i2n )' icon="pi pi-external-link"
							style="font-weight: 800;margin-top: 0.625rem;" label="阿里云盘下载(提取码: 1i2n )"
							class="p-button-help mb-3 p-mr-2" />
					</a>
					<a href="https://pan.baidu.com/s/1C_LqOaiNlBbKSrOmaxJ8KQ?pwd=b889" target="_blank">
						<Button title='通过百度网盘下载(提取码: b889 )' icon="pi pi-external-link"
							style="font-weight: 800;margin-top: 0.625rem;" label="百度网盘下载(提取码: b889 )"
							class="p-button-info mb-3 p-mr-2" />
					</a>
					<transition-group name="p-messages" tag="div">
						<Message :severity="'success'"><span style="font-weight: 800;">支持windows 10/11 系统，支持批量转换 caj
								文件（.caj、.kdh、.hn），比web端成功率更高也更快</span></Message>
						<Message :severity="'success'"><span style="font-weight: 800;">增加PDF-OCR功能，可为图片 PDF
								增加可复制文字层，完美解决某些 caj 文档转换后不可复制文字的问题，支持批量操作</span></Message>
					</transition-group>
				</template>
			</Card>
<!-- 			<Card style='margin-top: 20px;'>
				<template v-slot:title>
					<h5>人工客服转换</h5>
				</template>

				<template v-slot:subtitle>
					<Button type="button" icon='pi pi-user' title='微信扫码添加客服' label="添加客服微信" @click="toggleUserWechat"
						class="p-button-info p-mr-2" />
					<OverlayPanel ref="user_wechat" appendTo="body" :showCloseIcon="true">
						<img src="../../public/assets/layout/images/helper.jpg"
							style='width: 300px;border-radius: 10px;' alt="charge" />
					</OverlayPanel>
				</template>

				<template v-slot:content>
					<transition-group name="p-messages" tag="div">
						<Message :severity="'info'"><span
								style="font-weight: 800;">为保证客服热情饱满的为大家服务，请大家需要客服帮助时提前主动给辛苦费。（收费标准：1元/篇）</span>
						</Message>
					</transition-group>
				</template>
			</Card> -->
			<!-- 			<Card style='margin-top: 20px;'>
				<template v-slot:title>
					<h5 style="color:#0081ff;font-weight: 700;">高性价比论文查重</h5>
				</template>
				<template v-slot:subtitle>
				</template>
				<template v-slot:content>
					<div
						style="display: flex;flex-direction: row;overflow-x: auto;margin: -10px 0px -20px 0px;background-color: #edf0f5;padding: 10px 10px 10px 0px;border-radius: 10px;">
						<div v-for="(item, j) of course_pics" :key="j">
							<a v-if='j>0' :href="item.pay_src" target="_blank" style="margin-left: 20px;">
								<img style="height: 170px;border-radius: 10px;" :src="item.img_src" />
							</a>
							<a v-if='j==0' :href="item.pay_src" target="_blank" style="margin-left: 20px;">
								<img style="height: 170px;border-radius: 10px;" :src="item.img_src" />
							</a>
						</div>
					</div>
				</template>
			</Card> -->
			<!-- 			<Card style='margin-top: 15px;'>
				<template v-slot:title>
					<h5>赞赏鸣谢-感谢你们默默的支持</h5>
				</template>
				<template v-slot:subtitle>
				</template>
				<template v-slot:content>
					<div style="display: flex;flex-direction: row;overflow-x: auto;margin-bottom: -20px;">
						<div v-for="(item, j) of charge_pics" :key="j">
							<img style="height: 200px;" :src="item" />
						</div>
					</div>
				</template>
			</Card> -->
		</div>
		<div class="p-col-12 p-md-6" style="height: 100%;">
			<Card>
				<template v-slot:title>
					<h5>上次签到记录</h5>
				<!-- 	<a href="https://www.aliyundrive.com/s/7NgTdZ64Rek" target="_blank">
						<Button title='通过阿里云盘下载(提取码: 26jn )' icon="pi pi-external-link"
							style="font-weight: 800;margin-top: 0.625rem;" label="阿里云盘下载(提取码: 26jn )"
							class="p-button-help mb-3 p-mr-2" />
					</a>
					<a href="https://pan.baidu.com/s/13W8W1aa24OnP6ZsdnqfR9Q" target="_blank">
						<Button title='通过百度网盘下载(提取码: maj9 )' icon="pi pi-external-link"
							style="font-weight: 800;margin-top: 0.625rem;" label="百度网盘下载(提取码: maj9 )"
							class="p-button-info mb-3 p-mr-2" />
					</a>
					<transition-group name="p-messages" tag="div">
						<Message :severity="'success'"><span style="font-weight: 800;">支持批量转换 caj
								文件（.caj、.kdh、.hn），比web端成功率更高也更快</span></Message>
						<Message :severity="'success'"><span style="font-weight: 800;">增加PDF-OCR功能，可为图片 PDF
								增加可复制文字层，完美解决某些 caj 文档转换后不可复制文字的问题，支持批量操作</span></Message>
					</transition-group> -->
				</template>
				<template v-slot:content>
					<Message :severity="'success'">
						<div style="font-weight: 800;">
						<p>签到时间：{{check_in_date}}</p>
						<p>临时会员有效期：{{valid_date}}</p>
						</div>
					</Message>
				</template>
			</Card>
		<!-- 	<Card style='margin-top: 20px;'>
				<template v-slot:content>
					<div style="margin-top: 20px;" align="center">
						<ins class="adsbygoogle" style="display:inline-block;width:468px;height:90px;"
							data-ad-client="ca-pub-8147879111198627" data-ad-slot="6938463114"></ins>
					</div>
				</template>
			</Card> -->
		</div>
	</div>
</template>

<script>
	import NetWorkService from '../service/NetWorkService.js';
	import uploadFile from './userComponents/uploadFile.vue';
	import MajorService from '../service/MajorService.js';
	import Utils from './Utils.vue';
	export default {
		name: '',
		props: {
			para_copy: {
				default: null,
				type: Object
			}
		},
		data() {
			return {
				page_height: 'auto',
				para: this.para_copy, //此后para可在methods中操作
				upload_url: '/api/caj/upload',
				show_add_paper_dialog: false,
				show_download_button: false,
				pdf_file_url: 'https://www.libertynlp.com',
				all_procedure_list: [{
						'name': '文件格式转换成功',
						'severity': 'success',
					},
					{
						'name': '文件转换失败，由于.caj格式复杂，由于操作系统原因，现在云端自助转换的不能保证所有文件都转换成功。可下载桌面端转换器再次尝试或联系客服转换。',
						'severity': 'error',
					}
				],
				course_pics: [{
						"img_src": "https://picasso.alicdn.com/imgextra/O1CNA1D94uPb1D2PKwovFmw_!!2965900158-0-psf.jpg_430x430q90.jpg",
						"pay_src": "https://s.click.taobao.com/IV9NPXu",
					},
					{
						"img_src": "https://img.alicdn.com/i1/2039628487/O1CN01q3Pjm72CZ69yFjESz_!!0-item_pic.jpg",
						"pay_src": "https://s.click.taobao.com/sN4NPXu",
					},
					{
						"img_src": "https://img.alicdn.com/i2/2208864350349/O1CN01MXABCY1ERsyO3IRzr_!!0-item_pic.jpg",
						"pay_src": "https://s.click.taobao.com/VTSXCXu",
					}
				],
				procedure_list: [

				],
				charge_pics: [],
				file_number: 0,
				file_time: '',
				user_info: {
					'base_info': {},
					'account_info': {},
				},
				check_in_date:'未签到',
				valid_date:'未签到',
			}
		},
		networkService: null,
		created() {
			this.networkService = new NetWorkService();
			this.MajorService = new MajorService();
		},
		components: {
			'uploadFile': uploadFile,
			'Utils': Utils,
		},
		mounted() {
			// window.addAds();
			this.getUserInfo();
			// window.addAds();
			// window.addAds();
			// window.addAds();
			// window.addAds();
			//屏幕变化时
			// var vm=this;
			// vm.resizeWin();
			// window.onresize = () => {
			// 	return (() => {
			// 		vm.resizeWin();
			// 	})();
			// };
			this.setCheckInList();
		},
		methods: {
			setCheckInList(){
				var check_in_list=this.$appState.user_info.check_in_list;
				// console.log('签到列表',check_in_list);
				this.setCheckInTime(check_in_list);
			},
			setCheckInTime(check_in_list){
				var check_in_length=check_in_list
				if (check_in_length>0){
					var record_time=check_in_list[0];
					this.check_in_date=this.getDateFromTime(record_time*1000);
					// console.log('签到日期',this_date);
					this.valid_date=this.getDateFromTime((record_time+86400)*1000);
					// console.log('有效日期',valid_date);
				}
			},
			async todayCheckIn() {
				var resp=await this.$refs.Utils.memberCheckIn();
				if (resp.code==200){
					var check_in_list=resp.data.check_in_list;
					this.setCheckInTime(check_in_list);
				}
			},
			//将时间戳格式化为日期
			getDateFromTime(record_time) {
				// 根据毫秒数构建 Date 对象
				Date.prototype.toLocaleString = function() {
					return this.getFullYear() + "-" + (this.getMonth() + 1) + "-" + this.getDate() + '  ' + this.getHours() +
						":" + this.getMinutes() + ":" + this.getSeconds();
				};
				// 根据毫秒数构建 Date 对象
				// Date.prototype.toLocaleString = function() {
				// 	return this.getFullYear() + "-" + (this.getMonth() + 1) + "-" + this.getDate();
				// };
				var date = new Date(parseInt(record_time));
				// 按重写的自定义格式，格式化日期
				var dateTime = date.toLocaleString();
				return dateTime;
			},
			course_toggle(event) {
				this.$refs.course_op.toggle(event);
			},
			toggle(event) {
				this.$refs.op.toggle(event);
			},
			toggleUserWechat(event) {
				this.$refs.user_wechat.toggle(event);
			},
			//屏幕变化调整样式
			resizeWin() {
				this.center_width = document.documentElement.scrollWidth;
				if (this.center_width <= 750) {
					this.page_height = "auto";
				} else {
					var windowHeight = document.documentElement.clientHeight;
					this.page_height = windowHeight + 'px';
					// console.log('高度', this.page_height);
				}
			},
			async getUserInfo() {
				//cookie存在之后再检查登录态
				var data = new URLSearchParams();
				var url = '/member/info';
				var resp = await this.networkService.postService(url, data);
				if (resp.code == 200) {
					this.user_info = resp.data.info;
				}
			},
			//一键复制文本
			copyLink() {
				// var explain_id='img_explain_'+j;
				document.getElementById('invote_link').select();
				document.execCommand("Copy");
				this.$toast.add({
					severity: 'success',
					summary: '复制提示',
					detail: '分享链接已复制到剪贴板',
					life: 2000
				});
			},
		},
	}
</script>

<style scoped lang="scss">
</style>
